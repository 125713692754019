import { AxiosPrivateFirebaseInstance } from "settings";
import { IResponse } from "types";
import { IUnusedBalance } from "types/balances";

export const getBalancesReport = async (asAtDate: string) => {
  const result = await AxiosPrivateFirebaseInstance.get(
    `/admin_actions/reports/balances/cc/${asAtDate}`
  );
  return result?.data?.data;
};

export const getBalancesReportAsCsv = async (asAtDate: string) => {
  const result = await AxiosPrivateFirebaseInstance.get(
    `/admin_actions/reports/balances/cc/${asAtDate}`,
    { headers: { Accept: "text/csv" } }
  );
  return result?.data;
};

export const getUnusedBalancesForEntity = async (
  entityId: string,
  generate?: boolean
): Promise<IResponse<IUnusedBalance>> => {
  const query = generate ? "?debug=true&generate=true" : "?debug=true";
  const result = await AxiosPrivateFirebaseInstance.get(
    `/admin_actions/reports/balances/unused/${entityId}${query}`
  );
  return result?.data;
};

export const getUnusedBalancesForAllOnboardedEntities = async (
  generate?: boolean
): Promise<IResponse<IUnusedBalance[]>> => {
  const query = generate ? "?debug=true&generate=true" : "?debug=true";
  const result = await AxiosPrivateFirebaseInstance.get(
    `/admin_actions/reports/balances/unused/all${query}`
  );
  return result?.data;
};

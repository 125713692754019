import { FC, useState, useEffect } from "react";
import {
  Button,
  message,
  Row,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";

import dayjs from "dayjs";
import "./EntityList.css";
import {
  openDoc,
  openQueryWithTimestamp,
  TCustomRouteComponentProps,
  toCurrencyNumber,
} from "utils";
import { Link } from "react-router-dom";

import db from "services/firestore";
import { IEntity, IEntityDetails } from "types";
import { ICurrentBalanceDoc } from "types/balances";
import { Firebase } from "services";

const getEntities = async () => {
  try {
    const entities = await db
      .collection("entities")
      .where("status", "==", "onboarded")
      .get()
      .then((query) => openQueryWithTimestamp(query, ["_created"]));

    return entities.sort((a, b) =>
      b._created.toISOString().localeCompare(a._created.toISOString())
    );
  } catch (error) {
    console.log(error);
  }
};

const getEntityDetails = async (entityId: string, entity: IEntity) => {
  try {
    const entityCompanyDetails = await db
      .collection("entities")
      .doc(entityId)
      .collection("onboarding")
      .doc("companyDetails")
      .get()
      .then((doc) => openDoc(doc) as IEntityDetails);

    return {
      ...entity,
      ddNextDate: entityCompanyDetails?.ddNextDate,
    };
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

type IEntityWithDDNextDate = IEntity & { ddNextDate?: string };

const sortEntitiesByDDNextDate = (
  a: IEntityWithDDNextDate,
  b: IEntityWithDDNextDate
  // @ts-ignore
) => dayjs(a.ddNextDate, "YYYY-MM-DD") - dayjs(b.ddNextDate, "YYYY-MM-DD");

const entityColumns = (isFetchedCompanyDetails: boolean) => [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY"),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/entity-detail/" + record.id}>{text}</Link>
          {!record.enabled && (
            <>
              {" "}
              <Tag color="red">DISABLED</Tag>
            </>
          )}
        </span>
      );
    },
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "CC Account Id",
    dataIndex: ["externalRefs", "ccId"],
    key: "ccId",
  },
  {
    title: "CC User/OnBehalfOf Id",
    dataIndex: ["externalRefs", "ccOnBehalfOfId"],
    key: "ccOnBehalfOfId",
  },
  ...(isFetchedCompanyDetails
    ? [
        {
          title: "DD Next Date",
          dataIndex: "ddNextDate",
          key: "ddNextDate",
          render: (ddNextDate) => {
            if (!ddNextDate) {
              return <span>No DD Next Date</span>;
            }

            return (
              <span>
                {dayjs(ddNextDate, "YYYY-MM-DD").format("DD MMM YYYY")}
              </span>
            );
          },
          sorter: sortEntitiesByDDNextDate,
        },
      ]
    : []),
];

const entityBalanceColumns = () => [
  {
    title: "Name",
    dataIndex: "entityName",
    key: "entityName",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/entity-detail/" + record.entityId}>{text}</Link>
          {!record.entityEnabled && (
            <>
              {" "}
              <Tag color="red">DISABLED</Tag>
            </>
          )}
        </span>
      );
    },
  },
  {
    title: "ID",
    dataIndex: "entityId",
    key: "entityId",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "ccId",
  },
  
  {
    title: "Currency Cloud Balance",
    dataIndex: "currencyCloud",
    key: "currencyCloud",
    align: "right" as const,
    sorter: (a, b) => a.currencyCloud - b.currencyCloud,
    render: (value) => 
      <div className="currencyAmount">
        {toCurrencyNumber(value)}
      </div>,
  },
  {
    title: "Actual Balance",
    dataIndex: "hedgeFlows",
    key: "hedgeFlows",
    align: "right" as const,
    sorter: (a, b) => a.hedgeFlows - b.hedgeFlows,
    render: (value, record) => {
      const isDiscrepancyInAmounts = 
        record.hedgeFlows !== undefined && 
        record.currencyCloud !== undefined &&
        Math.abs(record.hedgeFlows - record.currencyCloud) > 0.01;
      const discrepancyStyle = isDiscrepancyInAmounts
        ? { color: "red" }
        : {};
      
      return (
        <div className="currencyAmount" style={discrepancyStyle}>
          {toCurrencyNumber(value)}
        </div>
      );
    }
  },
  {
    title: "Statement Balance",
    dataIndex: "hedgeFlowsStatement",
    key: "hedgeFlowsStatement",
    align: "right" as const,
    sorter: (a, b) => a.hedgeFlowsStatement - b.hedgeFlowsStatement,
    render: (value) => {
      const discrepancyStyle = value < 0
        ? { color: "red" }
        : {};
      
      return (
        <div className="currencyAmount" style={discrepancyStyle}>
          {toCurrencyNumber(value)}
        </div>
      );
    }
  },
  {
    title: "Available Balance",
    dataIndex: "hedgeFlowsAvailable",
    key: "hedgeFlowsAvailable",
    align: "right" as const,
    sorter: (a, b) => a.hedgeFlowsAvailable - b.hedgeFlowsAvailable,
    render: (value) => {
      const discrepancyStyle = value < 0
        ? { color: "red" }
        : {};
      
      return (
        <div className="currencyAmount" style={discrepancyStyle}>
          {toCurrencyNumber(value)}
        </div>
      );
    }
  },
];

const EntityList: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [isFetchedCompanyDetails, setIsFetchedCompanyDetails] = useState(false);
  const [entities, setEntities] = useState<IEntity[] | IEntityWithDDNextDate[]>(
    []
  );

  useEffect(() => {
    getEntities().then((res) => {
      if (res) {
        setEntities(res);
      }
    });
  }, []);

  const fetchCompanyDetailsForAllEntities = async () => {
    const entitiesToReturn = [];

    const promises = entities.map((entity) =>
      getEntityDetails(entity.id, entity)
    );

    await Promise.all(promises)
      .then((res) => {
        res.forEach((entity) => {
          entitiesToReturn.push(entity);
        });
      })
      .catch((err) => {
        message.error(err);
      });

    setEntities(entitiesToReturn);
    setIsFetchedCompanyDetails(true);
  };

  const [isFetchedBalances, setIsFetchedBalances] = useState(false);
  const [currentBalances, setCurrentBalances] = useState(null as IBalanceTransformed[]);
  const [isFetchingCcBalances, setIsFetchingCcBalances] = useState(false);


  const subscribeToCurrentBalances = (
    callback: (balances: ICurrentBalanceDoc[]) => void
  ) => {
    try {
      const unsubscribe = db
        .collection("currentBalances")
        .onSnapshot((query) =>
          callback(openQueryWithTimestamp(query, ["_created"]))
        );
      return unsubscribe;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBalances = async () => {
    setIsFetchedBalances(true);
  };

  const refreshCcBalances = async () => {
    setIsFetchingCcBalances(true);
    try {
      await Firebase.refreshBalances('all');
      alert('CC Balances refreshed');
    } catch (error) {
      alert(`Error refreshing CC Balances: \n${error.response?.data?.error || error.message}`);
    }
    setIsFetchingCcBalances(false);
  };

  useEffect(() => {
    const transformBalances = (balances: (ICurrentBalanceDoc & {entityId: string, entityName: string, entityEnabled?: boolean})[]): IBalanceTransformed[] => {
      const result: Record<string, IBalanceTransformed> = {};
    
      balances.forEach(({ entityName, entityId, entityEnabled, currency, source, amount, availableAmount, statementBalance }) => {
        const key = `${entityName}-${entityId}-${currency}`;
    
        if (!result[key]) {
          result[key] = { entityName, entityId, entityEnabled, currency };
        }
    
        if (source === 'hedgeFlows') {
          result[key].hedgeFlows = amount;
          if (availableAmount !== undefined) {
            result[key].hedgeFlowsAvailable = availableAmount;
          }
          if (statementBalance !== undefined) {
            result[key].hedgeFlowsStatement = statementBalance;
          }
        } else if (source === 'currencyCloud') {
          result[key].currencyCloud = amount;
        }
      });
    
      return Object.values(result);
    };

    if (entities && isFetchedBalances) {
      const unsubscribe = subscribeToCurrentBalances((data) => {
        const entityIds = entities.map((entity) => entity.id);
        const filteredData = data.filter((balance) =>
          entityIds.includes(balance._owner) &&
          (balance.source === "hedgeFlows" || balance.source === "currencyCloud") //&&
          // (balance.amount !== 0 || balance.availableAmount !== 0)
        );
        const transformedData = filteredData.map((balance) => ({
          ...balance,
          entityId: balance._owner,
          entityName: entities.find((entity) => entity.id === balance._owner)?.name || "*** Entity Name Not Found ***",
          entityEnabled: entities.find((entity) => entity.id === balance._owner)?.enabled,
        }));
        const transformedBalanceData = transformBalances(transformedData)
        const transformedBalanceDataNoZeros = transformedBalanceData.filter((balance) => balance.hedgeFlows || balance.currencyCloud);
        const transformedDataSortedByName = transformedBalanceDataNoZeros.sort((a, b) => a.entityName.localeCompare(b.entityName));
        return setCurrentBalances(transformedDataSortedByName);
      });

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entities, isFetchedBalances]);

  interface IBalanceTransformed {
    entityName: string;
    entityId: string;
    entityEnabled?: boolean;
    currency: string;
    currencyCloud?: number;
    hedgeFlows?: number;
    hedgeFlowsAvailable?: number;
    hedgeFlowsStatement?: number;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {entities && (
        <Table
          title={() => (
            <Row justify="space-between">
              <Title level={2}>Onboarded Entities</Title>
              <Button onClick={fetchBalances}>
                Fetch Balances
              </Button>
              {isFetchedBalances && (
                <Button onClick={refreshCcBalances}>
                  Refresh CC Balances
                </Button>
              )}
              {isFetchingCcBalances && <Spin style={{ marginBottom: "16px" }} />}


              <Button onClick={fetchCompanyDetailsForAllEntities}>
                Fetch DD Next Date
              </Button>
            </Row>
          )}
          // @ts-ignore
          columns={isFetchedBalances ? entityBalanceColumns() : entityColumns(isFetchedCompanyDetails)}
          dataSource={isFetchedBalances ? currentBalances : entities}
        />
      )}
    </div>
  );
};

export default EntityList;

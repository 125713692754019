import { FC, useState, useEffect, useMemo } from "react";
import { Button, Input, Segmented, Spin, Switch, Table, Typography } from "antd";

import dayjs from "dayjs";
import "./Balances.css";
import { toCurrencyNumber, openDoc, TCustomRouteComponentProps, openQueryWithTimestamp } from "utils";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Firebase } from "services";

import db from "services/firestore";
import { IEntity, ILedgerTransactionDoc } from "types";
import DocViewer from "pages/Overview/components/DocViewer";
import WritableRecords from "./components/WritableRecords";
import { ICurrencyWallet, IWalletTransaction } from "./interfaces";
import { getLedgerRecord, getLedgerRecordsFromSourceId } from "services/firebase";
import useDebounce from "hooks/useDebounce";
import { ICurrentBalance } from "types/balances";

const subscribeToEntity = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const subscribeToWalletTransactionsUpdated = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("walletTransactions")
      .where("_owner", "==", entityId)
      .orderBy("_updated", "desc")
      .limit(10)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToWalletTransactionsCreated = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("walletTransactions")
      .where("_owner", "==", entityId)
      .orderBy("_created", "desc")
      .limit(10)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToHedgeFlowsBalances = (
  entityId: string,
  callback: (balances: ICurrentBalance[]) => void
) => {
  try {
    const unsubscribe = db
      .collection("currentBalances")
      .where("_owner", "==", entityId)
      .where("source", "==", "hedgeFlows")
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );
    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const Balances: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entity, setEntity] = useState(null as IEntity);
  const [wallet, setWallet] = useState(null as ICurrencyWallet);
  const [hedgeFlowsBalances, setHedgeFlowsBalances] = useState(null as ICurrentBalance[]);

  // Monitors for updates
  const [monitoredWalletCreated, setMonitoredWalletCreated] = useState(null as ICurrencyWallet);
  const debouncedWalletsCreated = useDebounce(monitoredWalletCreated, 2000);
  const [monitoredWalletUpdated, setMonitoredWalletUpdated] = useState(null as ICurrencyWallet);
  const debouncedWalletsUpdated = useDebounce(monitoredWalletUpdated, 2000);

  const [docToView, setDocToView] = useState(null);
  const [writableRecordsToView, setWritableRecordsToView] = useState(null);

  const [currency, setCurrency] = useState<string>();

  const [expandedRowData, setExpandedRowData] = useState({});
  const [onTheFly, setOnTheFly] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [hasFetchedDataForTheFirstTime, setHasFetchedDataForTheFirstTime] = useState<boolean>(false);

  const handleExpand = async (expanded, record: { key: string } & IWalletTransaction) => {
    if (expanded && !expandedRowData[record.key]) {
      try {
        // Fetch the data for the expanded row
        const sourceId = record.sourceId;
        const numberOfDashesInSourceId = (sourceId.match(/-/g) || []).length;
        const sourceIdIsDocId = numberOfDashesInSourceId >= 2;
        const data = sourceIdIsDocId ? await getLedgerRecordsFromSourceId({ sourceId, entityId }) 
        : await getLedgerRecord({ ledgerTransactionId: record.ledgerTransactionId, entityId }).then(data => data ? [data] : []);
        // Update the state with the fetched data
        setExpandedRowData((prev) => ({
          ...prev,
          [record.key]: data,
        }));
      } catch (error) {
        console.error("Error fetching nested data:", error);
      }
    }
  };

  const sortCurrencies = (currencies: string[]) => currencies.sort((a, b) => {
    const priorityOrder = ['GBP', 'EUR', 'USD'];
    const indexA = priorityOrder.indexOf(a);
    const indexB = priorityOrder.indexOf(b);
  
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB; // Sort based on priority order
    }
    if (indexA !== -1) return -1; // Priority items come first
    if (indexB !== -1) return 1;  // Priority items come first
    return a.localeCompare(b); // Sort remaining alphabetically
  });

  const currenciesForDropdown = useMemo(
    () => sortCurrencies(Object.keys(wallet || {})),
    [wallet]
  );

  // @ts-ignore
  const { entity_id: entityId } = useParams<{ entity_id: string }>();

  const [searchText, setSearchText] = useState('');

const currentWalletTransactionsFiltered = (wallet?.[currency]?.transactions || []).filter((item) =>
  Object.values(item).some((value) => value?.toString().toLowerCase().includes(searchText.toLowerCase()))
);

  const getTransactionColumns = ({ settledTransactions, setWritableRecordsToView }: { settledTransactions: boolean; setWritableRecordsToView; }) => {
    const transactionColumns = [
      {
        title: "Settled On",
        dataIndex: "settledOnTimestamp",
        key: "settledOnTimestamp",
        sorter: (a, b) => new Date(a.settledOnTimestamp).getTime() - new Date(b.settledOnTimestamp).getTime(),
        render: (date) => date ? dayjs(date).format("DD MMM YYYY HH:mm:ss") : "",
      },
      {
        title: "Transaction Date",
        dataIndex: "transactionTimestamp",
        key: "transactionTimestamp",
        sorter: (a, b) => new Date(a.transactionTimestamp).getTime() - new Date(b.transactionTimestamp).getTime(),
        render: (date, record) =>
          <Link to="#" onClick={() => setDocToView(record)}>
            {dayjs(date).format("DD MMM YYYY HH:mm:ss")}
          </Link>,
      },
      {
        title: "Reference",
        dataIndex: "reference",
        key: "reference",
        sorter: (a, b) => a.reference.localeCompare(b.reference)
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => a.type.localeCompare(b.type)
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        sorter: (a, b) => a.description.localeCompare(b.description)
      },
      {
        title: "Credit",
        dataIndex: "amountCredit",
        key: "amountCredit",
        align: "right" as const,
        sorter: (a, b) => a.amountCredit - b.amountCredit,
        render: (value) => 
          <div className="currencyAmount">
            {value ? toCurrencyNumber(value) : ""}
          </div>,
      },
      {
        title: "Debit",
        dataIndex: "amountDebit",
        key: "amountDebit",
        align: "right" as const,
        sorter: (a, b) => a.amountDebit - b.amountDebit,
        render: (value) => 
          <div className="currencyAmount">
            {value ? toCurrencyNumber(value) : ""}
          </div>,
      },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        align: "right" as const,
        sorter: (a, b) => a.balance - b.balance,
        render: (value) => 
          <div className="currencyAmount">
            {toCurrencyNumber(value)}
          </div>,
      },
      {
        title: "Source ID",
        dataIndex: "sourceId",
        key: "sourceId",
        render: (value) => {
          return (
            <span>
              <Link to={"/app/transfer/" + value}>{value}</Link>
            </span>
          );
        },
      },
      {
        title: "Write-to-source status",
        dataIndex: "sourceId",
        key: "sourceId",
        render: (value, record: IWalletTransaction) => {
          const writableResources= record.writableResources || [];
          const noRecords = writableResources.length === 0;
          const recordsButNoneAttempted = writableResources.length > 0 && writableResources.every(writable => !writable.status);
          const none = writableResources.every(writable => writable.status && writable.status !== 'success')
          const all = writableResources.every(writable => writable.status === 'success');
          const some = !none && !all;
          let writeStatusText = 'Unknown';
          if (noRecords) {
            writeStatusText = 'Nothing to write';
          } else if (recordsButNoneAttempted) {
            writeStatusText = 'Not attempted';
          } else if (none) {
            writeStatusText = 'All failed';
          } else if (all) {
            writeStatusText = 'All successful';
          } else if (some) {
            writeStatusText = 'Partial success';
          }
          return (
            <Link to="#" onClick={() => setWritableRecordsToView(writableResources)}>
              {writeStatusText}
            </Link>
          );
        },
      },
    ];

    if (settledTransactions) {
      return transactionColumns;
    } else {
      return transactionColumns.filter((column) => column.key !== "settledOnTimestamp" && column.key !== "balance");
    }
  }

  const getLedgerTransactionColumns = () => {
    const LedgerTransactionColumns = [
      {
        title: "Created",
        dataIndex: "_created",
        key: "_created",
        render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
      },
      {
        title: "Postings",
        dataIndex: "postings",
        key: "postings",
        render: (text, record: ILedgerTransactionDoc) => {
          return (
            <>
              {record.postings?.map((item) => {
                return (
                  <div className="currencyAmount">
                    {`${item.from}->${item.to}`} &nbsp;
                    <b>{item.currency}</b> &nbsp;
                    {toCurrencyNumber(item.amount)}
                  </div>
                );
              })}
            </>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Source ID",
        dataIndex: "sourceId",
        key: "sourceId",
        render: (text, record: ILedgerTransactionDoc) => {
          const sourceText = `${record.source} ${record.sourceEvent}`;
          return (
            <span>
              {sourceText}
            </span>
          );
        },
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (text, record) => {
          return (
            <span>
              <Link to="#" onClick={() => setDocToView(record)}>
                {text}
              </Link>
            </span>
          );
        },
      },
    ];

    return LedgerTransactionColumns;
  };

  useEffect(() => {
    console.log('currency', currency);
  }, [currency]);

  useEffect(() => {
    if (entityId) {
      setIsLoading(true);
      console.log('Updating wallet since we have entityId and/or onTheFly has changed');
      Firebase.getBalancesAndTransactions(entityId, onTheFly).then((data) =>
        {
          console.log('data', data);
          setWallet(data)
          const sortedWalletCurrencies = sortCurrencies(Object.keys(data));
          if (!currency && sortedWalletCurrencies[0]) {
            console.log('setting currency to ', sortedWalletCurrencies[0]);
            setCurrency(sortedWalletCurrencies[0]);
          }
        setIsLoading(false);
        setTimeout(() => setHasFetchedDataForTheFirstTime(true), 5000);
        }
      );
    }
  }, [entityId, onTheFly]);

  useEffect(() => {
    if (entityId && hasFetchedDataForTheFirstTime) {
      setIsLoading(true);
      console.log('Updating wallet based on monitored subscriptions');
      Firebase.getBalancesAndTransactions(entityId, onTheFly).then((data) =>
        {
          console.log('monitored wallet - data', data);
          setWallet(data)
          setIsLoading(false);
        }
      );
    }
  }, [debouncedWalletsCreated, debouncedWalletsUpdated, entityId]);



  useEffect(() => {
    if (entityId && !entity) {
      const unsubscribe = subscribeToEntity(entityId, (data) =>
        setEntity(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entityId, entity]);

  useEffect(() => {
    if (entityId) {
      const unsubscribe = subscribeToWalletTransactionsCreated(entityId, (data) =>
        setMonitoredWalletCreated(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entityId]);

  useEffect(() => {
    if (entityId) {
      const unsubscribe = subscribeToWalletTransactionsUpdated(entityId, (data) =>
        setMonitoredWalletUpdated(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entityId]);

  useEffect(() => {
    if (entityId) {
      const unsubscribe = subscribeToHedgeFlowsBalances(entityId, (data) =>
        setHedgeFlowsBalances(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entityId]);

  const onRegenerateWalletTransactionsClick = async (entityId) => {
      setIsLoading(true);
      setWallet(null);
      setHasFetchedDataForTheFirstTime(false);
      Firebase.regenerateWallet(entityId).then((results) => {
        Firebase.getBalancesAndTransactions(entityId, onTheFly).then((data) =>
          {
            console.log('data after regenerate', data);
            setWallet(data)
            setIsLoading(false);
          });
        if (!results.success) {
          alert(`Failed to regenerate wallet transactions for ${entityId}: ${results.message}`);
        }
    });
    setTimeout(() => setHasFetchedDataForTheFirstTime(true), 5000);
  }

  const getCurrentBalanceForSelectedCurrency = () => hedgeFlowsBalances?.find(balance => balance.currency === currency);

  const getWalletTransactionsDataSource = ({
    settled = true,
  }: { settled?: boolean } = {}) => {
    const dataSource = [...currentWalletTransactionsFiltered].filter(
      (transaction) => transaction.status !== "cancelled"
    );
    const dateSourceFiltered = settled
      ? dataSource.filter(
          (transaction) => transaction.settledOnTimestamp !== undefined
        )
      : dataSource.filter(
          (transaction) => transaction.settledOnTimestamp === undefined
        );
    const dataSourceToReturn = dateSourceFiltered.map((item) => ({
      ...item,
      key: item.ledgerTransactionId,
    }));

    if (onTheFly && !settled && !isLoading) {
      return dataSourceToReturn.reverse();
    } else {
      return dataSourceToReturn;
    }
  };

  return (
    <div>
      <>
        <DocViewer
          doc={docToView}
          isVisible={!!docToView}
          onClose={() => setDocToView(null)}
        />
        <WritableRecords
          records={writableRecordsToView}
          isVisible={!!writableRecordsToView}
          onClose={() => setWritableRecordsToView(null)}
        />
      </>
      <>
        <Title>Wallet: Balances and Transactions
        <span
          style={{ float: "right" }}
        >
          <span style={{fontSize: "18px"}}>On-the-fly:</span> 
          <Switch style={{ marginLeft: 10 }} checked={onTheFly} onChange={(e) => setOnTheFly(e)} />
          <Button
            style={{ marginLeft: 20 }}
            // disabled={isLoading}
            onClick={() => onRegenerateWalletTransactionsClick(entityId)}
          >
            Regenerate Wallet Transactions
          </Button>
          
        </span>
        </Title>
        <p>
          {entity && (
            <>
              For{" "}
              <Link to={"/app/entity-detail/" + entity.id}>{entity.name}</Link>
            </>
          )}
        </p>

        {!wallet && isLoading && <Spin style={{ marginBottom: "16px" }} />}
        {wallet && (
          <>
            <Segmented
              onChange={(value) => setCurrency(value as string)}
              options=
                {currenciesForDropdown.map((currencyForDropdown) => (
                  {
                    label: (
                      <div
                        style={{
                          padding: 4,
                          fontWeight: "bold",
                          minWidth: 100,
                        }}
                      >
                        <div style={{fontSize: "larger"}}>{currencyForDropdown}</div>
                        <div>{toCurrencyNumber(wallet[currencyForDropdown].balance)}</div>
                      </div>
                    ),
                    value: currencyForDropdown,
                  }
                ))}
            />

            {isLoading && <Spin style={{ marginLeft: "16px", paddingTop: "20px" }} />}

            <div style={{ marginTop: "16px" }}>
              <b>Balances Breakdown:</b> {' '}
              Statement/Wallet balance: {toCurrencyNumber(wallet[currency]?.balance)} - 
              Actual balance: {toCurrencyNumber(getCurrentBalanceForSelectedCurrency()?.amount)} - 
              Available balance: {toCurrencyNumber(getCurrentBalanceForSelectedCurrency()?.availableAmount)}
              <Input
                  placeholder="Search all data"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  style={{ marginBottom: 16, width: 200, float: "right" }}
                />
            </div>

            <br />

            {entityId && wallet && currency && wallet[currency] &&wallet[currency].transactions && (
              <>
                <Table
                  pagination={{defaultPageSize: 3, showSizeChanger: true}}
                  locale={{ emptyText: "No data" }}
                  title={() => <Title level={2}>Pending Transactions</Title>}
                  columns={getTransactionColumns({ settledTransactions: false, setWritableRecordsToView })}
                  dataSource={getWalletTransactionsDataSource({ settled: false })}
                  expandable={{
                    onExpand: handleExpand, // Called when a row is expanded/collapsed
                    expandedRowRender: (record: { key: string } & IWalletTransaction) => (
                      <Table
                        columns={getLedgerTransactionColumns()}
                        dataSource={expandedRowData[record.key] || []} // Use state to provide data for the nested table
                        pagination={false} // Optional: Turn off pagination for nested tables
                      />
                    ),
                  }}
                />
                <Table
                  title={() => <Title level={2}>Settled Transactions</Title>}
                  columns={getTransactionColumns({ settledTransactions: true, setWritableRecordsToView })}
                  dataSource={getWalletTransactionsDataSource({ settled: true })}
                  expandable={{
                    onExpand: handleExpand, // Called when a row is expanded/collapsed
                    expandedRowRender: (record: { key: string } & IWalletTransaction) => (
                      <Table
                        columns={getLedgerTransactionColumns()}
                        dataSource={expandedRowData[record.key] || []} // Use state to provide data for the nested table
                        pagination={false} // Optional: Turn off pagination for nested tables
                      />
                    ),
                  }}
                />
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default Balances;

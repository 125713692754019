import { TAdminPageKey, TCustomRouteComponent } from "utils";
import Overview from "pages/Overview/Overview";
import EntityOverview from "pages/EntityDetails/EntityDetails";
import EntityList from "pages/EntityList/EntityList";
import Onboarding from "pages/Onboarding/Onboarding";
import OnboardingDetails from "pages/OnboardingDetails/OnboardingDetails";
import RecipientsList from "pages/RecipientsList/RecipientsList";
import RecipientDetails from "pages/RecipientDetails/RecipientDetails";
import SendersList from "pages/SendersList/SendersList";
import SenderDetails from "pages/SenderDetails/SenderDetails";
import Funding from "pages/Funding/Funding";
import TrackedRates from "pages/TrackedRates/TrackedRates";
import House from "pages/House/House";
import Currencies from "pages/Currencies/Currencies";
import Countries from "pages/Countries/Countries";
import Markups from "pages/Markups/Markups";
import SystemVariables from "pages/SystemVariables/SystemVariables";
import RiskAnalysis from "pages/RiskAnalysis/RiskAnalysis";
import CurrencyRates from "pages/CurrencyRates/CurrencyRates";
import NaturesOfBusiness from "pages/NaturesOfBusiness/NaturesOfBusiness";
import Fees from "pages/Fees/Fees";
import Users from "pages/Users/Users";
import UserLogs from "pages/UserLogs/UserLogs";
import Charts from "pages/Charts/Charts";
import Contract from "pages/Contract/Contract";
import BulkPayments from "pages/BulkPayments/BulkPayments";
import Transfer from "pages/Transfer/Transfer";
import RiskContributions from "pages/RiskContributions/RiskContributions";
import GlobalFeatureFlags from "pages/GlobalFeatureFlags/GlobalFeatureFlags";
import EntityFeatureFlags from "pages/EntityFeatureFlags/EntityFeatureFlags";
import IntegrationDetails from "pages/IntegrationDetails/IntegrationDetails";
import ReportBalances from "pages/ReportBalances/ReportBalances";
import WriteLogDetails from "pages/WriteLogDetails/WriteLogDetails";
import AdminPageAccess from "pages/AdminPageAccess/AdminPageAccess";
import AdminUserRoles from "pages/AdminUserRoles/AdminUserRoles";
import AssetView from "pages/AssetView/AssetView";
import OpenBankingCollectionViewer from "pages/OpenBankingCollectionViewer/OpenBankingCollectionViewer";
import Ledger from "pages/Ledger/Ledger";
import { ApplicationVersion } from "pages";
import Balances from "pages/Balances/Balances";
import TransfersReview from "pages/TransfersReview/TransfersReview";
import UnusedBalancesReport from 'pages/Reports/UnusedBalancesReport/UnusedBalancesReport';
import FxMoveUpload from "pages/FxMoveUpload/fxMoveUpload";

interface ISidebarRoutes {
  pageKey: TAdminPageKey;
  title: string;
  subPages?: {
    subPage: string;
    name: string;
  }[];
}

export const SIDEBAR_ROUTES: ISidebarRoutes[] = [
  {
    pageKey: "overview",
    title: "Overview",
  },
  {
    pageKey: "house-account",
    title: "House Account",
  },
  {
    pageKey: "entities",
    title: "Entities and details",
  },
  {
    pageKey: "onboarding",
    title: "Entity Review",
  },
  {
    pageKey: "recipients",
    title: "Recipients Review",
  },
  {
    pageKey: "transfers-review",
    title: "Transfers Review",
  },
  {
    pageKey: "senders",
    title: "Senders Review",
  },
  {
    pageKey: "funding",
    title: "Funding",
  },
  {
    pageKey: "users",
    title: "Users",
  },
  {
    pageKey: "users-logs",
    title: "Users Logs",
  },
  {
    pageKey: "tracking",
    title: "Tracking",
  },
  {
    pageKey: "write-log-detail",
    title: "Write log tracker",
  },
  {
    pageKey: "asset-view",
    title: "Asset View",
  },
  {
    pageKey: "reports",
    title: "Reports",
    subPages: [
      {
        subPage: "unused-balances",
        name: "Unused Balances",
      },
      {
        subPage: "balances",
        name: "CC Balances",
      },
      {
        subPage: "charts",
        name: "Charts",
      },
    ],
  },
  {
    pageKey: "reference-data",
    title: "Reference Data",
    subPages: [
      {
        subPage: "system-variables",
        name: "System Variables",
      },
      {
        subPage: "currencies",
        name: "Currencies",
      },
      {
        subPage: "countries",
        name: "Countries",
      },
      {
        subPage: "markups",
        name: "Markups",
      },
      {
        subPage: "fees",
        name: "Fees",
      },
      {
        subPage: "risk-analysis",
        name: "Risk Analysis",
      },
      {
        subPage: "application-version",
        name: "Application Version",
      },
      {
        subPage: "risk-contributions",
        name: "Risk Contributions",
      },
      {
        subPage: "natures-of-business",
        name: "Nature of Business",
      },
      {
        subPage: "currency-rates",
        name: "Currency Rates",
      },
      {
        subPage: "fx-move-upload",
        name: "FX Move Upload",
      }
    ],
  },
  {
    pageKey: "ob-data",
    title: "OB Data",
    subPages: [
      {
        subPage: "direct-debits",
        name: "Direct Debits",
      },
      {
        subPage: "beneficiaries",
        name: "Beneficiaries",
      },
      {
        subPage: "periodic-payments",
        name: "Periodic Payments",
      },
      {
        subPage: "scheduled-payments",
        name: "Scheduled Payments",
      },
      {
        subPage: "transactions",
        name: "Transactions",
      }
    ],
  },
  {
    pageKey: "feature-flags",
    title: "Feature Flags",
    subPages: [
      {
        subPage: "global",
        name: "Global",
      },
      {
        subPage: "entity",
        name: "Entity",
      },
    ],
  },
  {
    pageKey: "settings",
    title: "Settings",
    subPages: [
      {
        subPage: "user-roles",
        name: "User Roles",
      },
      {
        subPage: "page-access",
        name: "Page Access",
      },
    ],
  },
];

interface IPrivateRoutes {
  pageKey: TAdminPageKey;
  subPage?: string;
  queryParams?: string;
  Component: TCustomRouteComponent;
}

export const PRIVATE_ROUTES: IPrivateRoutes[] = [
  {
    pageKey: "overview",
    Component: Overview,
  },
  {
    pageKey: "house-account",
    Component: House,
  },
  {
    pageKey: "entities",
    Component: EntityList,
  },
  {
    pageKey: "onboarding",
    Component: Onboarding,
  },
  {
    pageKey: "recipients",
    Component: RecipientsList,
  },
  {
    pageKey: "transfers-review",
    Component: TransfersReview,
  },
  {
    pageKey: "senders",
    Component: SendersList,
  },
  {
    pageKey: "funding",
    Component: Funding,
  },
  {
    pageKey: "users",
    queryParams: "/:user_id?",
    Component: Users,
  },
  {
    pageKey: "users-logs",
    Component: UserLogs,
  },
  {
    pageKey: "tracking",
    Component: TrackedRates,
  },
  {
    pageKey: "asset-view",
    Component: AssetView,
  },
  {
    pageKey: "reports",
    subPage: "/balances",
    Component: ReportBalances,
  },
  {
    pageKey: "reports",
    subPage: "/unused-balances",
    Component: UnusedBalancesReport,
  },
  {
    pageKey: "reports",
    subPage: "/charts",
    Component: Charts,
  },
  {
    pageKey: "reference-data",
    subPage: "/currencies",
    Component: Currencies,
  },
  {
    pageKey: "reference-data",
    subPage: "/countries",
    Component: Countries,
  },
  {
    pageKey: "reference-data",
    subPage: "/markups",
    Component: Markups,
  },
  {
    pageKey: "reference-data",
    subPage: "/risk-analysis",
    Component: RiskAnalysis,
  },
  {
    pageKey: "reference-data",
    subPage: "/fx-move-upload",
    Component: FxMoveUpload,
  },
  {
    pageKey: "reference-data",
    subPage: "/application-version",
    Component: ApplicationVersion,
  },
  {
    pageKey: "reference-data",
    subPage: "/currency-rates",
    Component: CurrencyRates,
  },
  {
    pageKey: "reference-data",
    subPage: "/system-variables",
    Component: SystemVariables,
  },
  {
    pageKey: "reference-data",
    subPage: "/natures-of-business",
    Component: NaturesOfBusiness,
  },
  {
    pageKey: "reference-data",
    subPage: "/risk-contributions",
    Component: RiskContributions,
  },
  {
    pageKey: "reference-data",
    subPage: "/fees",
    Component: Fees,
  },
  {
    pageKey: "feature-flags",
    subPage: "/global",
    Component: GlobalFeatureFlags,
  },
  {
    pageKey: "feature-flags",
    subPage: "/entity",
    Component: EntityFeatureFlags,
  },
  {
    pageKey: "settings",
    subPage: "/user-roles",
    Component: AdminUserRoles,
  },
  {
    pageKey: "settings",
    subPage: "/page-access",
    Component: AdminPageAccess,
  },
  // Inner routes
  {
    pageKey: "contract",
    queryParams: "/:contract_id",
    Component: Contract,
  },
  {
    pageKey: "transfer",
    queryParams: "/:transfer_id",
    Component: Transfer,
  },
  {
    pageKey: "bulk-payment",
    queryParams: "/:bulk_payment_id",
    Component: BulkPayments,
  },
  {
    pageKey: "sender-detail",
    queryParams: "/:sender_id",
    Component: SenderDetails,
  },
  {
    pageKey: "entity-detail",
    queryParams: "/:entity_id",
    Component: EntityOverview,
  },
  {
    pageKey: "integration-detail",
    queryParams: "/:entity_id",
    Component: IntegrationDetails,
  },
  {
    pageKey: "onboarding-detail",
    queryParams: "/:entity_id",
    Component: OnboardingDetails,
  },
  {
    pageKey: "write-log-detail",
    queryParams: "/:entity_id?",
    Component: WriteLogDetails,
  },
  {
    pageKey: "recipient-detail",
    queryParams: "/:recipient_id",
    Component: RecipientDetails,
  },
  {
    pageKey: "ob-data",
    subPage: "/direct-debits",
    Component: OpenBankingCollectionViewer,
  },
  {
    pageKey: "ob-data",
    subPage: "/beneficiaries",
    Component: OpenBankingCollectionViewer,
  },
  {
    pageKey: "ob-data",
    subPage: "/periodic-payments",
    Component: OpenBankingCollectionViewer,
  },
  {
    pageKey: "ob-data",
    subPage: "/scheduled-payments",
    Component: OpenBankingCollectionViewer,
  },
  {
    pageKey: "ob-data",
    subPage: "/transactions",
    Component: OpenBankingCollectionViewer,
  },
  {
    pageKey: "ledger",
    queryParams: "/:ledger_id",
    Component: Ledger,
  },
  {
    pageKey: "balances",
    queryParams: "/:entity_id",
    Component: Balances,
  }
];

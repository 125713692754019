import { FC, useEffect, useState } from "react";
import { Table, Typography, Descriptions, Button, message } from "antd";
import { toCurrencyNumber } from "utils";
import { Firebase } from "services";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { IUnusedBalance } from "types/balances";
import { Link } from "react-router-dom";

const { Title } = Typography;

interface UnusedBalancesProps {
  entityId: string;
}

const UnusedBalances: FC<UnusedBalancesProps> = ({ entityId }) => {
  const [data, setData] = useState<IUnusedBalance | null>(null);
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await Firebase.getUnusedBalancesForEntity(entityId);
        if (result?.success) {
          setData(result.data);
        }
      } catch (error) {
        console.error("Error fetching unused balances:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [entityId]);

  if (loading || !data) {
    return null;
  }

  // Combine all debug data into a single table structure
  const debugData = Object.keys(data.debugObject?.availableBalances || {}).map(
    (currency) => ({
      currency,
      actualBalance: data.debugObject?.availableBalances[currency]?.actual || 0,
      availableBalance:
        data.debugObject?.availableBalances[currency]?.available || 0,
      unfundedBalance: data.debugObject?.unfundedBalances?.[currency] || 0,
      unusedBalanceInEntityCurrency:
        data.debugObject?.unusedBalancesInEntityCurrency?.[currency] || 0,
    })
  );

  return (
    <div style={{ marginBottom: "40px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={4}>Unused Balances</Title>
        <Button
          type="link"
          onClick={() => setShowDetails(!showDetails)}
          icon={showDetails ? <UpOutlined /> : <DownOutlined />}
        >
          {showDetails ? "Hide Details" : "Show Details"}
        </Button>
      </div>

      <Descriptions bordered>
        <Descriptions.Item label="Unused Balance in Control Currency">
          {data.currency} {toCurrencyNumber(data.unusedBalance)}
        </Descriptions.Item>
        <Descriptions.Item label="Unused Balance in Entity Currency">
          {data.entityCurrency}{" "}
          {toCurrencyNumber(data.unusedBalanceInEntityCurrency)}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span>Payout Transfer</span>
              {data.payoutTransfer && (
                <Button
                  size="small"
                  loading={updating}
                  disabled={updating}
                  onClick={async () => {
                    setUpdating(true);
                    try {
                      const result = await Firebase.getUnusedBalancesForEntity(
                        entityId,
                        true
                      );
                      if (result?.success) {
                        setData(result.data);
                        message.success("Payout transfer updated");
                      }
                    } catch (error) {
                      message.error("Failed to update payout transfer");
                    } finally {
                      setUpdating(false);
                    }
                  }}
                >
                  Update
                </Button>
              )}
            </div>
          }
        >
          {data.payoutTransfer ? (
            <Link to={`/app/transfer/${data.payoutTransfer.id}`}>
              {data.payoutTransfer.id}{" "}
              <span style={{ whiteSpace: "nowrap" }}>
                {data.payoutTransfer.buyCurrency}{" "}
                {toCurrencyNumber(data.payoutTransfer.buyAmount)}
              </span>
            </Link>
          ) : data.unusedBalance > 0 ? (
            <span>Missing Payout Settings</span>
          ) : null}
        </Descriptions.Item>
      </Descriptions>

      {showDetails && (
        <>
          <Title level={5} style={{ marginTop: "20px" }}>
            Debug Details
          </Title>
          <Table
            dataSource={debugData}
            columns={[
              {
                title: "Currency",
                dataIndex: "currency",
                key: "currency",
              },
              {
                title: "Actual Balance",
                dataIndex: "actualBalance",
                key: "actualBalance",
                render: (value, record) =>
                  `${record.currency} ${toCurrencyNumber(value)}`,
              },
              {
                title: "Available Balance",
                dataIndex: "availableBalance",
                key: "availableBalance",
                render: (value, record) =>
                  `${record.currency} ${toCurrencyNumber(value)}`,
              },
              {
                title: "Unfunded Balance",
                dataIndex: "unfundedBalance",
                key: "unfundedBalance",
                render: (value, record) =>
                  `${record.currency} ${toCurrencyNumber(value)}`,
              },
              {
                title: `Unused Balance in ${data.entityCurrency}`,
                dataIndex: "unusedBalanceInEntityCurrency",
                key: "unusedBalanceInEntityCurrency",
                render: (value) =>
                  `${data.entityCurrency} ${toCurrencyNumber(value)}`,
              },
            ]}
            pagination={false}
          />
        </>
      )}
    </div>
  );
};

export default UnusedBalances;

import { FC, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  message,
} from "antd";
import ace from "brace";
import 'brace/mode/json';

import "antd/dist/antd.css";

import { Firebase } from "services";
import { UploadOutlined } from "@ant-design/icons";
import { TCustomRouteComponentProps } from "utils/routes";
import { JsonEditor as Editor } from "jsoneditor-react";
import { JSONstringifyOrder, openDoc } from "utils";

import db from "services/firestore";

const getFxMovePercentileForCcyPair = async (ccyPair: string) => {
  try {
    const doc = await db
      .collection("referenceData/fxMoves/percentile")
      .doc(ccyPair)
      .get()
      .then((doc) => openDoc(doc));

    return doc;
  } catch (error) {
    console.log(error);
  }
};

const getFxMoveCumulativeAbovePercentileForCcyPair = async (ccyPair: string) => {
  try {
    const doc = await db
      .collection("referenceData/fxMoves/cumulativeAbove")
      .doc(ccyPair)
      .get()
      .then((doc) => openDoc(doc));

    return doc;
  } catch (error) {
    console.log(error);
  }
};

const FxMoveUpload: FC<TCustomRouteComponentProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileForUploadPercentile, setFileForUploadPercentile] = useState<File | undefined>(undefined);
  const [fileForUploadCumulativeAbove, setFileForUploadCumulativeAbove] = useState<File | undefined>(undefined);
  // Inputs to get probability of a percentage move
  const [probCcyPair, setProbCcyPair] = useState<string | undefined>(undefined);
  const [probDays, setProbDays] = useState<number | undefined>(undefined);
  const [percentageMove, setPercentageMove] = useState<number | undefined>(undefined);

  // Inputs to get percentage move value for a given percentile
  const [percentileCcyPair, setPercentileCcyPair] = useState<string | undefined>(undefined);
  const [percentileDays, setPercentileDays] = useState<number | undefined>(undefined);
  const [percentile, setPercentile] = useState<number | undefined>(undefined);
  const [rate, setRate] = useState<number | undefined>(undefined);

  const [ccyPairForDocFetch, setCcyPairForDocFetch] = useState<string | undefined>(undefined);
  const [percentileDoc, setPercentileDoc] = useState<any | null>(null);
  const [cumulativeAboveDoc, setCumulativeAboveDoc] = useState<any | null>(null);

  const editPercentileEditorRef = useRef<Editor>(null);
  const editCumulativeAboveEditorRef = useRef<Editor>(null);
  const fileInputRefPercentile = useRef<HTMLInputElement>();
  const fileInputRefCumulativeAbove = useRef<HTMLInputElement>();

  const form = Form.useForm()[0];

  useEffect(() => {
    if (percentileDoc) {
      editPercentileEditorRef.current?.jsonEditor.update(percentileDoc);
    }
  }, [percentileDoc]);

  useEffect(() => {
    if (cumulativeAboveDoc) {
      editCumulativeAboveEditorRef.current?.jsonEditor.update(cumulativeAboveDoc);
    }
  }, [cumulativeAboveDoc]);



  const onUploadFxMoveFiles = async (
    ccyPair: string
  ) => {
    try {
      setIsLoading(true);
      let allSuccess = true
      if (fileForUploadPercentile) {
        const formDataPercentile = new FormData();
        formDataPercentile.append('file', fileForUploadPercentile);
        const resultPercentileFile = await Firebase.uploadFxMovePercentileFile(formDataPercentile, ccyPair);
        if (!resultPercentileFile.data.success) {
          message.error(resultPercentileFile.data.message);
          allSuccess = false;
        }
      }

      if (fileForUploadCumulativeAbove) {
        const formDataCumulativeAbove = new FormData();
        formDataCumulativeAbove.append('file', fileForUploadCumulativeAbove);
        const resultCumulativeAboveFile = await Firebase.uploadFxMoveCumulativeAboveFile(formDataCumulativeAbove, ccyPair);
        if (!resultCumulativeAboveFile.data.success) {
          message.error(resultCumulativeAboveFile.data.message);
          allSuccess = false;
        }
      }

      if (allSuccess) {
        message.success(
          `Uploaded file(s) successfully`
        );
      }

      form.resetFields();

    } catch (error) {
      message.error(error);
      form.resetFields();
    } finally {
      setIsLoading(false);
    }
  };

  const onGetProbabilityOfPercentageFxMove = async () => {
    try {
      const result = await Firebase.getProbabilityOfPercentageFxMove({ ccyPair: probCcyPair, days: probDays, percentageMove: percentageMove });

      if (!result.data.success) {
        message.error(result.data.message);
      } else {
        message.success(<>
          probability: {result.data.data.probabilityOfPercentageMoveAsString} <br />
          startRating: {result.data.data.starRating}
        </>
        );
      }
    } catch (error) {
      message.error(error);
    }
  };

  const onGetPercentageMoveValueForPercentile = async () => {
    try {
      const result = await Firebase.getPercentageMoveValueForPercentile({ ccyPair: percentileCcyPair, days: percentileDays, percentile, rate });

      if (!result.data.success) {
        message.error(result.data.message);
      } else {
        message.success(<>
          percentageMove: {result.data.data.percentageMoveForPercentileAsString} <br />
          rate: {result.data.data.rate} <br />
          adjustedRate: {result.data.data.adjustedRate}
        </>
        );
      }
    } catch (error) {
      message.error(error);
    }
  };

  return (
    <>
      <Row>
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          onFinish={async ({ ccyPair }) => {
            onUploadFxMoveFiles(ccyPair);
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="ccyPair"
                label="CCY Pair"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Entity ccyPair (sellCyy/buyCcy) 6 char code here"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Button
                style={{
                  marginBottom: 16,
                }}
                icon={<UploadOutlined />}
                onClick={() => {
                  fileInputRefPercentile.current.click();
                }}
              >
                Upload new Percentile CSVfile
              </Button>
              <input
                ref={fileInputRefPercentile}
                type="file"
                multiple
                hidden
                onChange={async (event) => {
                  const files = event.target.files;

                  const filesArray = Array.from(files);
                  if (filesArray.length === 0) {
                    message.error("Please select a file to upload");
                    return;
                  }

                  if (filesArray.length > 1) {
                    message.error("Only one file can be uploaded at a time");
                    return;
                  }

                  setFileForUploadPercentile(filesArray[0]);

                  event.target.value = null;
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Button
                style={{
                  marginBottom: 16,
                }}
                icon={<UploadOutlined />}
                onClick={() => {
                  fileInputRefCumulativeAbove.current.click();
                }}
              >
                Upload new Cumulative Above CSV file
              </Button>
              <input
                ref={fileInputRefCumulativeAbove}
                type="file"
                multiple
                hidden
                onChange={async (event) => {
                  const files = event.target.files;

                  const filesArray = Array.from(files);
                  if (filesArray.length === 0) {
                    message.error("Please select a file to upload");
                    return;
                  }

                  if (filesArray.length > 1) {
                    message.error("Only one file can be uploaded at a time");
                    return;
                  }

                  setFileForUploadCumulativeAbove(filesArray[0]);

                  event.target.value = null;
                }}
              />
            </Col>
          </Row>
          <Row>

            <Form.Item>
              <div
                style={{
                  textAlign: "right",
                  marginTop: 16,
                }}
              >
                <Button disabled={isLoading} htmlType="submit" type="primary">
                  Create
                </Button>
              </div>
            </Form.Item>
          </Row>
        </Form>
      </Row>

      <Row>
        <Form>
          <Row>
            <Col span={6}>
              <Form.Item>
                <Input
                  placeholder="ccyPair"
                  type="string"
                  id="probCcyPair"
                  onChange={(e) => setProbCcyPair(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Input
                  placeholder="Days"
                  type="number"
                  id="probDays"
                  onChange={(e) => setProbDays(Number(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Input
                  placeholder="% Move"
                  type="number"
                  id="probPercentageMove"
                  onChange={(e) => setPercentageMove(Number(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button type="primary" onClick={onGetProbabilityOfPercentageFxMove}>Get Probability of FX move by{" "} {percentageMove}% or greater amount</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>

      <Row>
        <Form>
          <Row>
            <Col span={6}>
              <Form.Item>
                <Input
                  placeholder="percentile ccyPair"
                  type="string"
                  id="percentileCcyPair"
                  onChange={(e) => setPercentileCcyPair(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Input
                  placeholder="Percentile Days"
                  type="number"
                  id="percentileDays"
                  onChange={(e) => setPercentileDays(Number(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Input
                  placeholder="Percentile"
                  type="number"
                  id="percentile"
                  onChange={(e) => setPercentile(Number(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Input
                  placeholder="rate"
                  type="number"
                  id="rate"
                  onChange={(e) => setRate(Number(e.target.value))}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button type="primary" onClick={onGetPercentageMoveValueForPercentile}>Get percentage move value for a given percentile (e.g., the FX move percentage in the 90th percentile)</Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>

      <Row>
        <input
          type="text"
          placeholder="ccyPair"
          onChange={(e) => setCcyPairForDocFetch(e.target.value)}
        />
        <Button onClick={async () => {
          const doc = await getFxMovePercentileForCcyPair(ccyPairForDocFetch)
          setPercentileDoc(JSON.parse(JSONstringifyOrder(doc)))
        }}>Fetch Percentile Docs for {ccyPairForDocFetch}</Button>
        <Button onClick={async () => {
          const doc = await getFxMoveCumulativeAbovePercentileForCcyPair(ccyPairForDocFetch)
          setCumulativeAboveDoc(JSON.parse(JSONstringifyOrder(doc)))
        }}>Fetch Cumulative Above Docs for {ccyPairForDocFetch}</Button>
      </Row>

      <p>Percentile doc</p>
      <Editor
        ace={ace}
        ref={editPercentileEditorRef}
        theme="ace/theme/github"
        value={percentileDoc}
        id="percentileDoc"
        mode="text"
      />
      <p>Cumulative Above doc</p>
      <Editor
        ace={ace}
        ref={editCumulativeAboveEditorRef}
        theme="ace/theme/github"
        value={cumulativeAboveDoc}
        id="cumulativeAboveDoc"
        mode="text"
      />
    </>
  );
};

export default FxMoveUpload;

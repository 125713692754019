import { FC, useEffect, useState } from "react";
import { Table, Typography, Spin, Button, message } from "antd";
import { Link } from "react-router-dom";
import { Firebase } from "services";
import { toCurrencyNumber } from "utils";
import { IUnusedBalance } from "types/balances";

const { Title } = Typography;

const UnusedBalancesReport: FC = () => {
  const [data, setData] = useState<IUnusedBalance[]>([]);
  const [loading, setLoading] = useState(true);
  const [regenerating, setRegenerating] = useState(false);
  const [updatingEntities, setUpdatingEntities] = useState<Set<string>>(
    new Set()
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result =
          await Firebase.getUnusedBalancesForAllOnboardedEntities();
        if (result?.success) {
          setData(result.data);
        }
      } catch (error) {
        console.error("Error fetching unused balances:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "entityName",
      key: "entityName",
      render: (text: string, record: IUnusedBalance) => (
        <Link to={`/app/entity-detail/${record.entityId}`}>{text}</Link>
      ),
      sorter: (a: IUnusedBalance, b: IUnusedBalance) =>
        a.entityName.localeCompare(b.entityName),
    },
    {
      title: "ID",
      dataIndex: "entityId",
      key: "entityId",
    },
    {
      title: "Payout Transfer",
      key: "payoutTransfer",
      render: (_, record: IUnusedBalance) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {record.payoutTransfer ? (
            <>
              <Link to={`/app/transfer/${record.payoutTransfer.id}`}>
                {record.payoutTransfer.id}{" "}
                <span style={{ whiteSpace: "nowrap" }}>
                  {record.payoutTransfer.buyCurrency}{" "}
                  {toCurrencyNumber(record.payoutTransfer.buyAmount)}
                </span>
              </Link>
              <Button
                size="small"
                loading={updatingEntities.has(record.entityId)}
                disabled={updatingEntities.has(record.entityId)}
                onClick={async (e) => {
                  e.stopPropagation();
                  setUpdatingEntities((prev) =>
                    new Set(prev).add(record.entityId)
                  );
                  try {
                    const result = await Firebase.getUnusedBalancesForEntity(
                      record.entityId,
                      true
                    );
                    if (result?.success) {
                      setData(
                        data.map((item) =>
                          item.entityId === record.entityId ? result.data : item
                        )
                      );
                      message.success("Payout transfer updated");
                    }
                  } catch (error) {
                    message.error("Failed to update payout transfer");
                  } finally {
                    setUpdatingEntities((prev) => {
                      const next = new Set(prev);
                      next.delete(record.entityId);
                      return next;
                    });
                  }
                }}
              >
                Update
              </Button>
            </>
          ) : record.unusedBalance > 0 ? (
            <span>Missing Payout Settings</span>
          ) : null}
        </div>
      ),
    },
    {
      title: "Control Currency Unused Balance",
      key: "controlBalance",
      render: (_, record: IUnusedBalance) =>
        `${record.currency} ${toCurrencyNumber(record.unusedBalance)}`,
      sorter: (a: IUnusedBalance, b: IUnusedBalance) =>
        a.unusedBalance - b.unusedBalance,
      align: "right" as const,
    },
    {
      title: "Entity Currency Unused Balance",
      key: "entityBalance",
      render: (_, record: IUnusedBalance) =>
        `${record.entityCurrency} ${toCurrencyNumber(
          record.unusedBalanceInEntityCurrency
        )}`,
      sorter: (a: IUnusedBalance, b: IUnusedBalance) =>
        a.unusedBalanceInEntityCurrency - b.unusedBalanceInEntityCurrency,
      align: "right" as const,
    },
  ];

  const expandedRowRender = (record: IUnusedBalance) => {
    const debugData = Object.keys(
      record.debugObject?.availableBalances || {}
    ).map((currency) => ({
      currency,
      actualBalance:
        record.debugObject?.availableBalances[currency]?.actual || 0,
      availableBalance:
        record.debugObject?.availableBalances[currency]?.available || 0,
      unfundedBalance: record.debugObject?.unfundedBalances?.[currency] || 0,
      unusedBalanceInEntityCurrency:
        record.debugObject?.unusedBalancesInEntityCurrency?.[currency] || 0,
    }));

    return (
      <Table
        columns={[
          {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
          },
          {
            title: "Actual Balance",
            dataIndex: "actualBalance",
            key: "actualBalance",
            render: (value, row) =>
              `${row.currency} ${toCurrencyNumber(value)}`,
            align: "right" as const,
          },
          {
            title: "Available Balance",
            dataIndex: "availableBalance",
            key: "availableBalance",
            render: (value, row) =>
              `${row.currency} ${toCurrencyNumber(value)}`,
            align: "right" as const,
          },
          {
            title: "Unfunded Balance",
            dataIndex: "unfundedBalance",
            key: "unfundedBalance",
            render: (value, row) =>
              `${row.currency} ${toCurrencyNumber(value)}`,
            align: "right" as const,
          },
          {
            title: `Unused Balance in ${record.entityCurrency}`,
            dataIndex: "unusedBalanceInEntityCurrency",
            key: "unusedBalanceInEntityCurrency",
            render: (value) =>
              `${record.entityCurrency} ${toCurrencyNumber(value)}`,
            align: "right" as const,
          },
        ]}
        dataSource={debugData}
        pagination={false}
      />
    );
  };

  if (loading) {
    return (
      <div>
        <Title level={2}>Unused Balances Report</Title>
        <Spin />
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={2}>Unused Balances Report</Title>
        <Button
          loading={regenerating}
          disabled={regenerating}
          onClick={async () => {
            setRegenerating(true);
            try {
              const result =
                await Firebase.getUnusedBalancesForAllOnboardedEntities(true);
              if (result?.success) {
                setData(result.data);
                message.success(
                  "Payout transfers regenerated for all entities"
                );
              }
            } catch (error) {
              message.error("Failed to regenerate payout transfers");
            } finally {
              setRegenerating(false);
            }
          }}
        >
          Regenerate Payout Transfers for all entities
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: [],
        }}
        rowKey="entityId"
        pagination={{ pageSize: 50 }}
      />
    </div>
  );
};

export default UnusedBalancesReport;

import { conformToMask } from 'react-text-mask';
import dayjs from 'dayjs';
import IMask from 'imask';

import { ICurrency } from 'types';

export const asyncForEach = async (array: any[], callback: Function) => {
  for (let index = 0; index < array.length; index += 1) {
    await callback(array[index], index, array);
  }
};

export const fixPrecision = (num: number): number => {
  return Number(num.toFixed(2));
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const toCurrencyNumber = (value, precision = 2) : string => {
  let valueToUse = value === 0 ? 0 : value; // Hack to avoid -0

  if (typeof value === 'undefined') {
    return '';
  }

  if (typeof value === 'string') {
    if (Number.isNaN(value)) {
      return '';
    }
    valueToUse = Number(value);
  }

  const pipe = IMask.createPipe(
    {
      mask: Number,
      scale: precision,
      radix: '.',
      thousandsSeparator: ',',
      normalizeZeros: true,
      padFractionalZeros: precision > 0 ? true : false,
      signed: true,
    },
    IMask.PIPE_TYPE.TYPED,
    IMask.PIPE_TYPE.MASKED
  );

  return pipe(roundToPrecision(valueToUse, precision));
};

export const roundToPrecision = (amount: number, precision = 2) => {
  if (!amount) return amount;

  const usePrecision = Math.pow(10, precision);

  const roundedAmount = Math.round(amount * usePrecision) / usePrecision;

  return roundedAmount;
};

export const parseIntoDateString = (value: Date) => {
  const valueAsString = dayjs(value, 'MM DD YYYY').format('MM DD YYYY');

  const parsedValue = conformToMask(
    valueAsString,
    [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    {}
  ).conformedValue;

  return parsedValue;
};

export const parseRateWithPrecision = (rate: number) =>
  rate ? rate.toPrecision(rate < 1 ? 4 : 5) : '';

export const getCountryCodeByCurrency = (
  currencyCode: ICurrency['code'],
  currencies: ICurrency[]
): ICurrency['countryCode'] => {
  const currency = currencies.find((item) => item.code === currencyCode);

  if (currency) {
    return currency.countryCode;
  }

  return '';
};

export const replaceStringAt = (
  originalText: string,
  textForReplacement: string,
  index: number
) => {
  return (
    originalText.substr(0, index) +
    textForReplacement +
    originalText.substr(index + textForReplacement.length)
  );
};

export const getUniqueDecreasingNumber = (inject: string = undefined) => {
  const dateAsNumber = new Date().getTime();
  let id = (9999999999999 - dateAsNumber).toString();
  (inject) && (id = id + '-' + inject);
  id = id + '-' + Math.floor(100000 + Math.random() * 900000).toString();
  return id;
}

export const convertFireStoreTimestampToDateString = firestoreTimestamp => {
  try {
    const firestoreTimestampAsDate = firestoreTimestamp.toDate();
    const offset = firestoreTimestampAsDate.getTimezoneOffset();
    const yourDate = new Date(firestoreTimestampAsDate.getTime() - offset * 60 * 1000);
    return yourDate.toISOString().split('T')[0];
  } catch (err) {
    return '0000';
  }
};

export const convertFireStoreTimestampToDateTimeString = firestoreTimestamp => {
  try {
    const firestoreTimestampAsDate = firestoreTimestamp.toDate();
    const offset = firestoreTimestampAsDate.getTimezoneOffset();
    const yourDate = new Date(firestoreTimestampAsDate.getTime() - offset * 60 * 1000);
    return yourDate.toISOString();
  } catch (err) {
    return undefined;
  }
};

export const JSONstringifyOrder = (obj: any, space = 2) => {
  delete obj.__details;
  const allKeys = [];
  const seen = {};
  JSON.stringify(obj, (key, value) => {
    if (!(key in seen)) {
      allKeys.push(key as never);
      seen[key] = null;
    }
    return value;
  });
  allKeys.sort();
  return JSON.stringify(obj, allKeys, space);
}
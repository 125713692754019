import { IResponse } from "types/networking"
import {
  AxiosPrivateFirebaseInstance,
} from "settings";

export const uploadFxMovePercentileFile = async (file: FormData, ccyPair: string) => {
  const response = await AxiosPrivateFirebaseInstance.post<IResponse>(
    `admin_actions/fx-move/${ccyPair}/percentile`,
    file
)

  return response;
}

export const uploadFxMoveCumulativeAboveFile = async (file: FormData, ccyPair: string) => {
  const response = await AxiosPrivateFirebaseInstance.post<IResponse>(
    `admin_actions/fx-move/${ccyPair}/cumulative-above`,
    file
  )

  return response;
}

export const getProbabilityOfPercentageFxMove = async ({ccyPair, days, percentageMove}: {ccyPair: string, days: number, percentageMove: number}) => {
  // create url params
  const params = new URLSearchParams();
  params.append('days', days.toString());
  params.append('percentage_move', percentageMove.toString());

  const response = await AxiosPrivateFirebaseInstance.get<IResponse<{
    days : number;
    daysToUse: number;
    percentageMoveToUse: number;
    percentageMove: number;
    probabilityOfPercentageMove: number;
    probabilityOfPercentageMoveAsString: string;
    starRating: number;
  }>>(
    `analysis/fx-move/${ccyPair}/percentage-move-probability`,
    { params }
  )

  return response;
}

export const getPercentageMoveValueForPercentile = async ({ccyPair, days, percentile, rate}: {ccyPair: string, days: number, percentile: number, rate?: number}) => {
  // create url params
  const params = new URLSearchParams();
  params.append('days', days.toString());
  params.append('percentile', percentile.toString());
  if (rate) {
    params.append('rate', rate.toString());
  }

  const response = await AxiosPrivateFirebaseInstance.get<IResponse<{
    days: number;
    daysToUse: number;
    percentileToUse: number;
    percentile: number;
    percentageMoveForPercentile: number;
    percentageMoveForPercentileAsString: string;
    rate?: number;
    adjustedRate?: number;
  }>>(
    `analysis/fx-move/${ccyPair}/percentile-percentage-move`,
    { params }
  )

  return response;
}
import { FC, useState, useEffect } from "react";
import { Table, Typography, Tag, Tooltip, message, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import db from "services/firestore";
import { openQueryWithTimestamp } from "utils";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { ISystemFieldsWithOwner } from "types/systemFields";

/**
 * Interface for OCR background check results
 */
interface IOcrBackgroundCheck {
  id?: string;
  /** The type of OCR background check */
  type: 'invoiceContact';
  /** The ID of the invoice associated with this check */
  invoiceId: string;
  /** The ID of the contact associated with this check */
  contactId: string;
  /** The ID of the attachment that was processed */
  attachmentId: string;
  /** Timestamp in ISO format when the check was performed */
  checkTimestamp: string;
  /** Results of the OCR check */
  results: Record<string, string>;
  /** Whether the OCR data matches the contact data */
  isMatched: boolean;
  /** List of field names that didn't match */
  unmatchedFields: string[];
}

type IOcrBackgroundCheckDoc = IOcrBackgroundCheck & ISystemFieldsWithOwner;

const { Title } = Typography;

/**
 * Converts camelCase string to Title Case with spaces
 */
const toTitleCase = (str: string): string => {
  if (!str) return '';
  
  // Add space before capital letters and uppercase the first character
  const result = str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase());
  
  return result.trim();
};

/**
 * Copy text to clipboard and show success message
 */
const copyToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text)
    .then(() => {
      message.success(`Copied to clipboard: ${text}`);
    })
    .catch(() => {
      message.error('Failed to copy');
    });
};

const subscribeToOcrBackgroundChecks = (entityId: string, callback: (data: IOcrBackgroundCheckDoc[]) => void) => {
  try {
    const unsubscribe = db
      .collection("ocrBackgroundChecks")
      .where("_owner", "==", entityId)
      .orderBy("_created", "desc")
      .onSnapshot((query) => 
        callback(openQueryWithTimestamp(query, ["_created", "checkTimestamp"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const OcrBackgroundChecks: FC = () => {
  const [ocrChecks, setOcrChecks] = useState<IOcrBackgroundCheckDoc[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  
  // @ts-ignore - entity_id is available in URL params
  const { entity_id } = useParams();

  useEffect(() => {
    if (entity_id) {
      setLoading(true);
      const unsubscribe = subscribeToOcrBackgroundChecks(entity_id, (data) => {
        setOcrChecks(data);
        setLoading(false);
      });

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [entity_id]);

  // Style for truncated text
  const ellipsisStyle = {
    maxWidth: 'calc(100% - 28px)',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' as const,
    verticalAlign: 'middle',
    cursor: 'pointer'
  };

  // Copyable ID cell renderer
  const renderCopyableId = (text: string) => (
    <Tooltip title={`${text} (Click to copy)`}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span 
          style={ellipsisStyle} 
          onClick={(e) => {
            e.stopPropagation();
            copyToClipboard(text);
          }}
        >
          {text}
        </span>
        <Button 
          type="primary" 
          size="small"
          icon={<CopyOutlined />} 
          onClick={(e) => {
            e.stopPropagation();
            copyToClipboard(text);
          }}
          style={{ padding: '0 4px', marginLeft: '4px' }}
        />
      </div>
    </Tooltip>
  );

  const columns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date: any) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
      sorter: (a: IOcrBackgroundCheckDoc, b: IOcrBackgroundCheckDoc) => 
        new Date(a._created).getTime() - new Date(b._created).getTime(),
    },
    {
      title: "Check Date",
      dataIndex: "checkTimestamp",
      key: "checkTimestamp",
      render: (date: string) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: string) => toTitleCase(type),
      filters: [
        { text: 'Invoice Contact', value: 'invoiceContact' }
      ],
      onFilter: (value: string, record: IOcrBackgroundCheckDoc) => record.type === value,
    },
    {
      title: "Invoice ID",
      dataIndex: "invoiceId",
      key: "invoiceId",
      ellipsis: true,
      width: '15%',
      render: renderCopyableId
    },
    {
      title: "Attachment ID",
      dataIndex: "attachmentId",
      key: "attachmentId",
      ellipsis: true,
      width: '15%',
      render: renderCopyableId
    },
    {
      title: "Contact ID",
      dataIndex: "contactId",
      key: "contactId",
      ellipsis: true,
      width: '15%',
      render: (text: string) => (
        <Tooltip title={text}>
          <Link 
            to={`/app/recipient-detail/${text}`} 
            target="_blank" 
            rel="noopener noreferrer"
            style={ellipsisStyle}
          >
            {text}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: "Matched",
      dataIndex: "isMatched",
      key: "isMatched",
      render: (matched: boolean) => (
        <Tag color={matched ? "green" : "red"}>
          {matched ? "Yes" : "No"}
        </Tag>
      ),
      filters: [
        { text: 'Matched', value: true },
        { text: 'Not Matched', value: false }
      ],
      onFilter: (value: boolean, record: IOcrBackgroundCheckDoc) => record.isMatched === value,
    },
    {
      title: "Unmatched Fields",
      dataIndex: "unmatchedFields",
      key: "unmatchedFields",
      render: (fields: string[]) => 
        fields && fields.length > 0 
          ? fields.map(field => <Tag key={field} color="orange">{toTitleCase(field)}</Tag>) 
          : "None",
    },
  ];

  return (
    <Table
      title={() => <Title level={2}>OCR Background Checks</Title>}
      columns={columns}
      dataSource={ocrChecks}
      rowKey="id"
      loading={loading}
      expandable={{
        expandedRowRender: (record) => (
          <div>
            <Title level={5}>OCR Results</Title>
            <Table
              columns={[
                { 
                  title: 'Field', 
                  dataIndex: 'field', 
                  key: 'field', 
                  render: (field: string) => toTitleCase(field) 
                },
                { 
                  title: 'Value', 
                  dataIndex: 'value', 
                  key: 'value',
                  ellipsis: {
                    showTitle: false,
                  },
                  render: (value: string) => (
                    <Tooltip title={value}>
                      <span style={{...ellipsisStyle, cursor: 'default'}}>{value}</span>
                    </Tooltip>
                  )
                }
              ]}
              dataSource={Object.entries(record.results).map(([field, value]) => ({
                key: field,
                field,
                value
              }))}
              pagination={false}
              size="small"
            />
          </div>
        ),
      }}
    />
  );
};

export default OcrBackgroundChecks; 